import { useState } from "react"

export const BookingStates = () => {
    const [loader, setLoader] = useState(true)
    const [inftScroll, setInftScroll] = useState(true)
    const [bookingDetails, setBookingDetails] = useState([])  
    const [lid, setLid] = useState()
    const [drawers, setDrawers] = useState({abdetails: false, abdates: false})
    const [receipt, setReceipt] = useState(null)
    const [promoD, setPromoD] = useState([])
    const [form, setForm] = useState({
        method: null,
        step: 1,
        submit: {toggled: false, type: '', confirmed: false},
        inputs: {
            qty: {value: '1', stat: false, msg: '', toggled: false},
            contact_num: {value: '', stat: true, msg: '', toggled: false},
            last_name: {value: '', stat: false, msg: '', toggled: false},
            first_name: {value: '', stat: false, msg: '', toggled: false},
            email: {value: '', stat: true, msg: '', toggled: false},
            date: {value: '', stat: false, msg: '', toggled: false},
            book_time: {value: '', stat: false, msg: '', toggled: false},
        }
    })
    const [btn] = useState([
            {name: 'Booked', url: 'booked'},
            {name: 'For Check-In', url: 'check-in'},
            {name: 'Check-Out', url: 'check-out'},
    ])
    const [err, setErr] = useState(false)
    const [search, setSearch] = useState('')
    const [viewLoader, setViewLoader] = useState(false)
    const [list, setList] = useState([])
    const [dateClosed, setDateClosed] = useState([])
    const [showMyDiscount, setShowMyDiscount] = useState(false)
    const [discountList, setDiscountList] = useState([])
    const [review, setReview] = useState({ overall: 0, facilities: 0, staff: 0, cleanliness: 0, comfort: 0, location: 0, remarks: '', files: [], btnLoader: false, error: false, type: 'create', viewLoader: false, })
    const [fileMsg, setFileMsg] = useState('')
    const [toExpire, setToExpire] = useState('')
    const [viewImg, setViewImg] = useState({show: false, index: 0})
    const [promoDetails, setPromoDetails] = useState({info: '', reviews: [], loader: true, reviews_count: 0})
    const [reviewsList, setReviewsList] = useState([])
    const [storeItems, setStoreItems] = useState([])

    return {
        loader: {data:loader, set: setLoader},
        inftScroll: {data:inftScroll, set: setInftScroll},
        bookingDetails: {data:bookingDetails, set: setBookingDetails},
        lid: {data:lid, set: setLid},
        form: {data: form, set: setForm},
        drawers: {data: drawers, set: setDrawers},
        receipt: {data: receipt, set: setReceipt},
        promoD: {data: promoD, set: setPromoD},
        btn: {data: btn},
        err: {data: err, set: setErr},
        search: {data: search, set: setSearch},
        viewLoader: {data: viewLoader, set: setViewLoader},
        list: {data: list, set: setList},
        dateClosed: {data: dateClosed, set: setDateClosed},
        showMyDiscount: {data: showMyDiscount, set: setShowMyDiscount},
        discountList: {data: discountList, set: setDiscountList},
        review: {data: review, set: setReview},
        fileMsg: {data: fileMsg, set: setFileMsg},
        toExpire: {data: toExpire, set: setToExpire},
        viewImg: {data: viewImg, set: setViewImg},
        promoDetails: {data: promoDetails, set: setPromoDetails},
        reviewsList: {data: reviewsList, set: setReviewsList},
        storeItems: {data: storeItems, set: setStoreItems},
    }
}