import { useState } from 'react'

export const ProductState = () => {
    const [form, setForm] = useState({quantity: 1, last_name: '', first_name: '', contact_number:'', email: '', delivery_option: 'delivery', city: '', brgy: '',street: '' , date: '', time: '', remarks: '', pickup_time:'' , pickup_date:'', dragonpay: true,  sub_total: '', total_amount:'' , shipping_fee: 0, shipping_id: '' })
    const [promoPage, setPromoPage] = useState([])
    const [viewLoader, setViewLoader] = useState(true)
    const [error, setError] = useState(false)
    const [confirmation, setConfirmation] = useState(false)
    const [btnLoader, setBtnLoader] = useState(false)
    const [city, setCity] = useState([])
    const [brgy, setBrgy] = useState([])
    const [ship, setShip] =useState([])
    const [orderList, setOrderList] = useState([])
    const [loaderList, setLoaderList] = useState(false)
    const [showdetails, setShowDetails] = useState(false)
    const [receipt, setReceipt] = useState(null)
    const [prodList, setProdList] = useState(false)
    const [loader, setLoader] = useState(true)
    const [inftScroll, setInftScroll] = useState(true)
    const [lid, setLid] = useState()
    const [search, setSearch] = useState('')
    const [dtError, setDtError] = useState({date: false, time: false})
    const [confirmId, setConfirmId] = useState()
    const [review, setReview] = useState({ overall: 0, product_rate: 0, product_remarks: '', seller_rate: 0, seller_remarks: '', shipping_rate: 0, shipping_remarks: '', files: [], btnLoader: false, error: false, type: 'create', viewLoader: false, })
    const [fileMsg, setFileMsg] = useState('')
    const [viewImg, setViewImg] = useState({show: false, index: 0})
    const [promoDetails, setPromoDetails] = useState({info: '', reviews: [], loader: true, reviews_count: 0})
    const [storeItems, setStoreItems] = useState([])
    const [reviewsList, setReviewsList] = useState([])

    return {
        form: {data: form, set: setForm},
        promoPage: {data: promoPage, set: setPromoPage},
        viewLoader: {data: viewLoader, set: setViewLoader},
        error: {data: error, set: setError},
        confirmation: {data: confirmation, set: setConfirmation},
        btnLoader: {data: btnLoader, set: setBtnLoader},
        city: {data: city, set: setCity},
        brgy: {data: brgy, set: setBrgy},
        ship: {data: ship, set: setShip},
        orderList: {data: orderList, set: setOrderList},
        loaderList: {data: loaderList, set: setLoaderList},
        showdetails: {data: showdetails, set: setShowDetails},
        receipt: {data: receipt, set: setReceipt},
        prodList: {data: prodList, set: setProdList},
        loader: {data: loader, set: setLoader},
        inftScroll: {data: inftScroll, set: setInftScroll},
        lid: {data: lid, set: setLid},
        search: {data: search, set: setSearch},
        dtError: {data: dtError, set: setDtError},
        confirmId: {data: confirmId, set: setConfirmId},
        review: {data: review, set: setReview},
        fileMsg: {data: fileMsg, set: setFileMsg},
        viewImg: {data: viewImg, set: setViewImg},
        promoDetails: {data: promoDetails, set: setPromoDetails},
        storeItems: {data: storeItems, set: setStoreItems},
        reviewsList: {data: reviewsList, set: setReviewsList},
    }
}
