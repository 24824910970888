import { useContext, useEffect, useState } from "react"
import { ToolContext } from "../../../core/context/ToolContext";
import { env } from "../../../core/Env";
import { rqx } from "../../../core/request/API";
import moment from "moment";
// import moment from "moment";

// const tool_id =  process.env.REACT_APP_TID_PSL;
// const global_id = process.env.REACT_APP_GLOBAL_ID;

export const RenderAPI = () => {
    const { tool_state } = useContext(ToolContext)
    const { __SESSION, globalLoader, toolOwned, validateAccount} = tool_state

    const __init = async() => {
        await globalLoader.set(true)
        // await __runactiveuser()
       
        //GET TOOL OWNED
        let prms = {aid:__SESSION.data.ainfo.aid, platform:'personal' } 
        let resTool = await rqx.get(`<<global>>/${env()==='local'?'dev':env()}/tools/owned`, prms)
        if (window.atob(__SESSION.data.ainfo.aid).split('_')[1] !== '0') {     
            let body = {
                action:"signin",
                aid: __SESSION.data.ainfo.aid,
                date: moment(new Date()).format('YYYY-MM-DD'),
                tool_id: process.env.REACT_APP_TID_PSL, 
            }
            await rqx.post(`<<global>>/${env()}/trail/create`, body)
        }
        
        // const res_city = await rqx.get(`<<global>>/${env()}/location/city`, { ref: 'province_id', filter: JSON.stringify([vl[0].province_id]) });
        // const res_barangay = await rqx.get(`<<global>>/${env()}/location/barangay`, { ref: 'city_id', filter: JSON.stringify([vl[0].city_id]) });
        toolOwned.set(resTool)

        await globalLoader.set(false)
    }

    const [subscribe, setSubscribe] = useState(true)
  
    useEffect(() => {
        if (subscribe) {
            if (globalLoader.data) {
                if (__SESSION.data.ainfo !== null) {
                    __init()
                }
            }
        }
        return () => setSubscribe(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [validateAccount.data])
    
    return (
        <></>
    )
}