import { lazy } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { Box, Container } from '@mui/material';
import { VM } from '../VM';

import { Index as ToolCategories } from '../../__personal/components/toolcategories/Index';
import { RenderAPI } from '../../__personal/components/global/RenderAPI';
import { Switching } from '../../__personal/components/swtiching/Index'
import { Settings } from '../../__personal/components/settings/Index';
import { DragonpayCb } from '../../__personal/components/cb/Index';

let view = VM();
const Navigation = lazy(() => import(`../../__personal/components/layouts/navigations${view}/Index`))
const Home = lazy(() => import (`../../__personal/components/home${view}/Index`))
const Booking = lazy(() => import(`../../__personal/components/myBooking${view}/Index`))
const MyReward = lazy(() => import(`../../__personal/components/myRewards${view}/Index`))
const PromoPage = lazy(() => import(`../../__personal/components/promoPage${view}/Index`))
const Shop = lazy(() => import(`../../__personal/components/shop${view}/Index`))
const MyCart = lazy(() => import(`../../__personal/components/myCart${view}/Index`))
const SalesPage = lazy(() => import(`../../__personal/components/salesPage${view}/Index`))
const RealProperty = lazy(() => import(`../../__personal/components/realProperty${view}/Index`))

export const WebRoute = () => {
    const location = useLocation().pathname;
    
    return (
        <Switch>
            {/* HOME */}
            <Route exact path="/psl" render={props=>(Render(Home, props, location))}/>
            <Route exact path="/psl/switching/:params" render={props=><Switching {...props}/>} />
            <Route exact path="/psl/more" render={props=>(Render(Settings, props, location))} />
            <Route exact path="/psl/tool-categories/:params" render={props=>(Render(ToolCategories, props, location))} />
            <Route exact path="/psl/booking/:type" render={props=>(Render(Booking, props, location))} />
            <Route exact path="/psl/myrewards/:type" render={props=>(Render(MyReward, props, location))} />
            <Route exact path="/psl/:name/:type/:id" render={props=>(Render(PromoPage, props, location))} />
            <Route exact path="/psl/shop/:type" render={props=>(Render(Shop, props, location))} />
            <Route exact path="/psl/mycart/:type" render={props=>(Render(MyCart, props, location))} />
            <Route exact path="/psl/successpayment" render={props=>(Render(DragonpayCb, props, location))} />
            <Route exact path="/psl/:name/:type/:tab/:id" render={props => (Render(SalesPage, props, location))} />
            <Route exact path="/psl/real-property/:type" render={props => (Render(RealProperty, props, location))} />
            {/* 404 NOT FOUND */}
            <Route>
                <Container>
                    <Box display="flex">
                        Error 404
                    </Box>
                </Container>
            </Route>
        </Switch>
    )
}

const Render = (Component, props, location) => (
    
    <>
        {view === '' ? (
            <Box width="100%" height="100%">
                <Box width="100%" height="100%" display="flex">
                    <Box height="100%">
                        {(location !== '/psl/profiling') && (<Navigation side={1} />)}
                    </Box>
                    <Box display="flex" flexDirection="column" width="100%" height="100%">
                        <Navigation side={0} />
                        <Box height="100%" className="overflowY noScrollcss">
                            <Component {...props} />
                        </Box>
                    </Box>
                </Box>
            </Box>
        ) : (
            <Box width="100%" height="100%">
                <Box width="100%" height="100%" display="flex" flexDirection="column">
                    {(location !== '/psl/profiling' && location !== '/psl/more' && location !== '/psl/myrewards/redeemlist' && location !== '/psl/myrewards/coupon' && location !== '/psl/booking/form' && location !== '/psl/booking/preview' && location !== '/psl/shop/form' && location !== '/psl/shop/receipt' && location.split('/')[2] !== 'promo-page' && location.split('/')[2] !== 'preview' && location.split('/')[2] !== 'sales-page' && location !== '/psl/real-property/view') && (
                        <Box minHeight={location === '/psl' ? '92px' : '40px'}>
                            <Navigation side={0} />
                        </Box>
                    )}
                    <Box height="100%" width="100%" className="overflowY noScrollcss"><Component {...props} /></Box>
                    {(location !== '/psl/profiling' && location !== '/psl/myrewards/redeemlist' && location !== '/psl/myrewards/coupon' && location !== '/psl/booking/form' && location !== '/psl/booking/preview' && location !== '/psl/shop/form' && location !== '/psl/shop/receipt' && location.split('/')[2] !== 'promo-page' && location.split('/')[2] !== 'preview' && location.split('/')[2] !== 'sales-page' && location !== '/psl/real-property/view') &&
                        <Box minHeight="54px">
                            <Navigation side={2} />
                        </Box>
                    } 
                </Box>
            </Box>
        )}
        <RenderAPI/>
    </>
)