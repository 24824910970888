import moment from "moment";
import { rqx } from "../../core/request/API";
import { env } from "../../core/Env";
import sha1 from "js-sha1";

export const ProductUbr = async(data, ainfo) => {
    const d = await rqx.get(`<<hrc>>/${env()}/template/view`, {id: data.pid})
    let body;
    let dflt = {
        last_name: data.inputs.last_name,
        first_name: data.inputs.first_name,
        contact_num: data.inputs.contact_number,
        email: data.inputs.email,
        quantity: data.inputs.quantity,
        remarks: data.inputs.remarks, 
        total_amount: data.inputs.total_amount,
        delivery_option: data.inputs.delivery_option === 'delivery' ? 1 : 0,
        promo_id: data.pid,
        user_id: parseInt(window.atob(ainfo.aid).split('_')[1]),
        status: 1,
    }
    body = {...dflt,
        city: data.inputs.city,
        brgy: data.inputs.brgy,
        street: data.inputs.street,
        date: moment(data.inputs.date).format('MM-DD-YYYY'),
        time: data.inputs.time,
        shipping_id: d.data[0].free_shipping === 1 || d.data[0].shipping_type === 2 ||  data.inputs.delivery_option === "pick_up" ? 0 : data.inputs.shipping_id,
        pickup_date: data.inputs.delivery_option === 'delivery' ? null  : moment(data.inputs.pickup_date).format('MM-DD-YYYY'),
        pickup_time: data.inputs.delivery_option === 'delivery' ? null  : data.inputs.pickup_time,
        delivery_pickup_info_id: d.data[0].delivery_pickup_info_id,
    }
    // if (data.inputs.delivery_option === 'delivery') {
    // }else{
    //     body = {...dflt,
    //         pickup_date: moment(data.inputs.pickup_date).format('MM-DD-YYYY'),
    //         pickup_time: data.inputs.pickup_time
    //     }
    // }
    
    const res = await rqx.post(`<<tool>>/${env()}/product/create`, body)
    if (res.message === 'success') {
        let params = {
            'merchantid': 'I4ONE2',
            'txnid': (Math.random() + 1).toString(36).substring(7),
            'amount': data.inputs.total_amount.toFixed(2),
            'ccy': 'PHP',
            'description': `From : ${data.inputs.first_name} ${data.inputs.last_name}`,
            'email': data.inputs.email
        }

        let d = {...params, key: env() === 'prod' ? '8dUtLoysr7A95pH' : 'rCPAaSbFmHAkoav'}
        let digest = ''
        for (const i in d) {
            if (digest === '') {
                digest = `${d[i]}`
            }else{
                digest = `${digest}:${d[i]}`
            }
        }
        let dfinal = {...params, mode: '129', 'param1': encodeURIComponent(JSON.stringify({cb:data.inputs.delivery_option === 'delivery' ? 'products_del' : 'products_pickup', aid: parseInt(window.atob(ainfo.aid).split('_')[1])})), 'param2': encodeURIComponent(JSON.stringify({oid: res.order_id, promo_id: data.pid})), digest: sha1(digest)}
        let subqsp = []
        Object.keys(dfinal).map(k => subqsp.push(`${k}=${encodeURIComponent(dfinal[k])}`));
        let qsp = `${subqsp.join('&')}`
        window.location.href = env() === 'prod' ? `http://gw.dragonpay.ph/Pay.aspx?${qsp}` : `http://test.dragonpay.ph/Pay.aspx?${qsp}`
    }
}
