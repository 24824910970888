import { useEffect } from 'react'
import { Box } from '@mui/material'
import { Logo } from '../../../core/global/Icons'
import { SnakeLoader } from '../../../core/loader/SnakeLoader'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

export const DragonpayCb = () => {
    const urlparams = new URLSearchParams(window.location.search)
    const history = useHistory()
    
    useEffect(() => {
        if (JSON.parse(decodeURIComponent(urlparams.get('param1'))).cb === 'booking') {
            history.push(`/psl/booking/form?step=receipt&param1=${urlparams.get('param1')}&param2=${urlparams.get('param2')}`)
        }else{
            history.push(`/psl/shop/receipt?param1=${urlparams.get('param1')}&param2=${urlparams.get('param2')}`)
        }
        // eslint-disable-next-line
    }, [])
    return (
        <Box height="100%" width="100%" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <Logo size={60}/>
            <Box mt={4}>
                <SnakeLoader size="1rem" bg="#3D77E9" distance="0.3rem" />
            </Box>
        </Box>
    )
}
