import { Box } from '@mui/material'

// export const Steps = () => [
//     {
//         target: '.step-one',
//         content: (
//             <>
//                <Box display="flex" justifyContent="space-between">
//                     <Box fontSize={16} fontWeight="bold" color="#0070EF">Dashboard</Box>
//                     <Box fontSize={12} color="#A2A2A2"> 1 of 9</Box>
//                 </Box>
//                 <Box fontSize={12} mt={2} color="#1B1B1B" textAlign="left">Quickly and easily access your summary data. </Box>
//             </>
//         ),
//         disableBeacon : true,
//         placement: "bottom",
//     },
//     {
//         target: '.step-two',
//         content: (
//             <>
//                <Box display="flex" justifyContent="space-between">
//                     <Box fontSize={16} fontWeight="bold" color="#0070EF">Booking</Box>
//                     <Box fontSize={12} color="#A2A2A2"> 1 of 9</Box>
//                 </Box>
//                 <Box fontSize={12} mt={2} color="#1B1B1B" textAlign="left">Booking promos and reservations</Box>
//             </>
//         ),
//         disableBeacon : true,
//         placement: "right",
//     },
//     {
//         target: '.step-three',
//         content: (
//             <>
//                <Box display="flex" justifyContent="space-between">
//                     <Box fontSize={16} fontWeight="bold" color="#0070EF">Shop</Box>
//                     <Box fontSize={12} color="#A2A2A2"> 1 of 9</Box>
//                 </Box>
//                 <Box fontSize={12} mt={2} color="#1B1B1B" textAlign="left">Purchase your goods and products.</Box>
//             </>
//         ),
//         disableBeacon : true,
//         placement: "right",
//     },
//     {
//         target: '.step-four',
//         content: (
//             <>
//                <Box display="flex" justifyContent="space-between">
//                     <Box fontSize={16} fontWeight="bold" color="#0070EF">Real Property</Box>
//                     <Box fontSize={12} color="#A2A2A2"> 1 of 9</Box>
//                 </Box>
//                 <Box fontSize={12} mt={2} color="#1B1B1B" textAlign="left">Purchase or rent a property.
// </Box>
//             </>
//         ),
//         disableBeacon : true,
//         placement: "right",
//     },
//     {
//         target: '.step-five',
//         content: (
//             <>
//                <Box display="flex" justifyContent="space-between">
//                     <Box fontSize={16} fontWeight="bold" color="#0070EF">More</Box>
//                     <Box fontSize={12} color="#A2A2A2"> 1 of 9</Box>
//                 </Box>
//                 <Box fontSize={12} mt={2} color="#1B1B1B" textAlign="left">More enjoyable and engaging shopping experience, fostering customer satisfaction and loyalty.</Box>
//             </>
//         ),
//         disableBeacon : true,
//         placement: "right",
//     },
//     {
//         target: '.step-six',
//         content: (
//             <>
//                <Box display="flex" justifyContent="space-between">
//                     <Box fontSize={16} fontWeight="bold" color="#0070EF">Cart</Box>
//                     <Box fontSize={12} color="#A2A2A2"> 1 of 9</Box>
//                 </Box>
//                 <Box fontSize={12} mt={2} color="#1B1B1B" textAlign="left">Items you want to purchase.</Box>
//             </>
//         ),
//         disableBeacon : true,
//         placement: "right",
//     },
//     {
//         target: '.step-seven',
//         content: (
//             <>
//                <Box display="flex" justifyContent="space-between">
//                     <Box fontSize={16} fontWeight="bold" color="#0070EF">Chat</Box>
//                     <Box fontSize={12} color="#A2A2A2"> 1 of 9</Box>
//                 </Box>
//                 <Box fontSize={12} mt={2} color="#1B1B1B" textAlign="left">Real-time communication for both personal and business.</Box>
//             </>
//         ),
//         disableBeacon : true,
//         placement: "right",
//     },
//     {
//         target: '.step-eight',
//         content: (
//             <>
//                <Box display="flex" justifyContent="space-between">
//                     <Box fontSize={16} fontWeight="bold" color="#0070EF">My Tools</Box>
//                     <Box fontSize={12} color="#A2A2A2"> 1 of 9</Box>
//                 </Box>
//                 <Box fontSize={12} mt={2} color="#1B1B1B" textAlign="left">Your availed tools and other personal tools.</Box>
//             </>
//         ),
//         disableBeacon : true,
//         placement: "right",
//     },
//     {
//         target: '.step-eight',
//         content: (
//             <>
//                <Box display="flex" justifyContent="space-between">
//                     <Box fontSize={16} fontWeight="bold" color="#0070EF">Notification</Box>
//                     <Box fontSize={12} color="#A2A2A2"> 1 of 9</Box>
//                 </Box>
//                 <Box fontSize={12} mt={2} color="#1B1B1B" textAlign="left">Notify you for your status of your transaction.</Box>
//             </>
//         ),
//         disableBeacon : true,
//         placement: "right",
//     },
// ]

export const Steps = [
    {
        target: '.step-one',
        content: (
            <>
               <Box display="flex" justifyContent="space-between">
                    <Box fontSize={16} fontWeight="bold" color="#0070EF">Dashboard</Box>
                    <Box fontSize={12} color="#A2A2A2"> 1 of 10</Box>
                </Box>
                <Box fontSize={12} mt={2} color="#1B1B1B" textAlign="left">Quickly and easily access your summary data. </Box>
            </>
        ),
        disableBeacon : true,
        placement: "top",
    },
    {
        target: '.step-two',
        content: (
            <>
               <Box display="flex" justifyContent="space-between">
                    <Box fontSize={16} fontWeight="bold" color="#0070EF">Booking</Box>
                    <Box fontSize={12} color="#A2A2A2"> 2 of 10</Box>
                </Box>
                <Box fontSize={12} mt={2} color="#1B1B1B" textAlign="left">Booking promos and reservations</Box>
            </>
        ),
        disableBeacon : true,
        placement: "top",
    },
    {
        target: '.step-three',
        content: (
            <>
               <Box display="flex" justifyContent="space-between">
                    <Box fontSize={16} fontWeight="bold" color="#0070EF">My Rewards</Box>
                    <Box fontSize={12} color="#A2A2A2"> 3 of 10</Box>
                </Box>
                <Box fontSize={12} mt={2} color="#1B1B1B" textAlign="left">Purchase your goods and products.</Box>
            </>
        ),
        disableBeacon : true,
        placement: "top",
    },
    {
        target: '.step-four',
        content: (
            <>
               <Box display="flex" justifyContent="space-between">
                    <Box fontSize={16} fontWeight="bold" color="#0070EF">Tool Categories</Box>
                    <Box fontSize={12} color="#A2A2A2"> 4 of 10</Box>
                </Box>
                <Box fontSize={12} mt={2} color="#1B1B1B" textAlign="left">Tools you need for your career growth.</Box>
            </>
        ),
        disableBeacon : true,
        placement: "top",
    },
    {
        target: '.step-five',
        content: (
            <>
               <Box display="flex" justifyContent="space-between">
                    <Box fontSize={16} fontWeight="bold" color="#0070EF">More</Box>
                    <Box fontSize={12} color="#A2A2A2"> 5 of 10</Box>
                </Box>
                <Box fontSize={12} mt={2} color="#1B1B1B" textAlign="left">More enjoyable and engaging shopping experience, fostering customer satisfaction and loyalty.</Box>
            </>
        ),
        disableBeacon : true,
        placement: "top",
    },
    {
        target: '.step-six',
        content: (
            <>
                <Box display="flex" justifyContent="space-between">
                    <Box fontSize={16} fontWeight="bold" color="#0070EF">Personal</Box>
                    <Box fontSize={12} color="#A2A2A2"> 6 of 10</Box>
                </Box>
                <Box textAlign="left" fontSize={12} mt={2} color="#516A7D">Switch to Personal to grow your career.</Box>
            </>
        ),
        placement: "bottom",
    },
    {
        target: '.step-seven',
        content: (
            <>
                <Box display="flex" justifyContent="space-between">
                    <Box fontSize={16} fontWeight="bold" color="#0070EF">Business</Box>
                    <Box fontSize={12} color="#A2A2A2"> 7 of 10</Box>
                </Box>
                <Box textAlign="left" fontSize={12} mt={2} color="#516A7D">Switch to Business to grow your business.</Box>
            </>
        ),
        placement: "bottom",
    },
    {
        target: '.step-eight',
        content: (
            <>
               <Box display="flex" justifyContent="space-between">
                    <Box fontSize={16} fontWeight="bold" color="#0070EF">Chat</Box>
                    <Box fontSize={12} color="#A2A2A2"> 8 of 10</Box>
                </Box>
                <Box fontSize={12} mt={2} color="#1B1B1B" textAlign="left">Real-time communication for both personal and business.</Box>
            </>
        ),
        disableBeacon : true,
        placement: "bottom",
    },
    {
        target: '.step-nine',
        content: (
            <>
               <Box display="flex" justifyContent="space-between">
                    <Box fontSize={16} fontWeight="bold" color="#0070EF">Notification</Box>
                    <Box fontSize={12} color="#A2A2A2"> 9 of 10</Box>
                </Box>
                <Box fontSize={12} mt={2} color="#1B1B1B" textAlign="left">Notify you for your status of your transaction.</Box>
            </>
        ),
        disableBeacon : true,
        placement: "bottom",
    },
    {
        target: '.step-tenth',
        content: (
            <>
               <Box display="flex" justifyContent="space-between">
                    <Box fontSize={16} fontWeight="bold" color="#0070EF">My Tools</Box>
                    <Box fontSize={12} color="#A2A2A2"> 10 of 10</Box>
                </Box>
                <Box fontSize={12} mt={2} color="#1B1B1B" textAlign="left">Your availed tools and other personal tools.</Box>
            </>
        ),
        disableBeacon : true,
        placement: "left",
    },
    
]