import { useState } from "react"
import { HomeIcons, NotificationIcons, TcIcons, MyToolIcons, ReferUserIcons, RenewIcons, Dashboard } from '../../global/Icons';
import { LocalMall, LocalMallOutlined, HomeWorkOutlined, HomeWork, BookOnline } from "@mui/icons-material";

export const NavigationStates = () => {
    const [tabs, setTabs] = useState({
        top: [
            {name:'Notifications', className:'', subdir:'/notification', ref: '/notification', not_active: <NotificationIcons fill="#333333" />, active: <NotificationIcons  fill="#3D77E9" />},
            {name:'MyTools', className:'tenth-step', subdir:'#', ref: '__mytools', not_active:<MyToolIcons fill="#292D32" />, active:<MyToolIcons fill="#3D77E9" stroke="#3D77E9" />},
        ],
        mtop: [
            {name:'Notifications', steps:'step-nine', subdir:'/notification', ref: '/notification', not_active: <NotificationIcons fill="#fff" />, active: <NotificationIcons fill="#3D77E9" />},
            {name:'MyTools', steps:'step-tenth', subdir:'#', ref: '__mytools', not_active:<MyToolIcons fill="#FFFFFF" stroke="#FFFFFF" />, active:<MyToolIcons fill="#3D77E9" stroke="#3D77E9"/>},
        ],
        left: [
            {name:'Dashboard', className:'first-step',  subdir:'/psl', ref: undefined, not_active:<HomeIcons stroke="#89A594" />, active:<HomeIcons fill="#3D77E9" stroke="#fff"/>},
            // {name:'Feature 1', className:'second-step', subdir:'/psl/feature1', ref: 'feature1', not_active:<HomeIcons stroke="#89A594" />, active:<HomeIcons fill="#3D77E9" stroke="#fff"/>},
            {name:'Tool Categories', className:'third-step', subdir:'/psl/tool-categories/subscribed', ref: 'tool-categories', not_active:<TcIcons stroke={'#89A594'}  fill={'#fff'} />, active:<TcIcons fill="#3D77E9" stroke="#3D77E9"/>},
        ],
        msetting: [
            {name:'Refer a Hero Users', subdir:'/psl', ref: 'psl', steps: 'sixth-step', not_active:<ReferUserIcons fill="#fff" stroke="#A2A3A9"/>, active:<ReferUserIcons fill="#333333" stroke="#333333"/>},
            {name:'System Updates', subdir:'/psl', ref: 'psl', steps: 'seventh-step', not_active:<RenewIcons fill="none" stroke="#89A594"/>, active:<RenewIcons fill="#333333" stroke="#333333"/>},
        ],
        mobile: [
            {name:'Dashboard', steps:'step-one', subdir:'/psl', ref: undefined, not_active:<Dashboard fill="#FFFFFF" stroke="#8496BB" size="20px"/>, active:<Dashboard fill="#3D77E9" stroke="#FFFFFF" size="20px" />},
            {name:'My Booking', steps:'step-two', subdir:'/psl/booking/promo-list', ref: 'booking', not_active:<BookOnline sx={{color: '#8496BB', fontSize: 20}} />, active:<BookOnline  sx={{color: '#3D77E9', fontSize: 20}}/>},
            {name:'Shop', subdir:'/psl/shop/list', ref: 'shop', not_active:<LocalMallOutlined sx={{color: '#8496BB'}} />, active:<LocalMall  sx={{color: '#3D77E9'}}/>},
            {name:'Real Propery', subdir:'/psl/real-property/list', ref: 'real-property', not_active:<HomeWorkOutlined sx={{color: '#8496BB'}} />, active:<HomeWork  sx={{color: '#3D77E9'}}/>},
            // {name:'My Rewards', steps:'step-three', subdir:'/psl/myrewards/mypoints', ref: 'myrewards', not_active:<RedeemOutlined sx={{color: '#8496BB', fontSize: 20}} />, active:<Redeem sx={{color: '#3D77E9', fontSize: 20}} />},
            // {name:'Tool Categories', sub_name:'', steps:'step-four', subdir:'/psl/tool-categories/subscribed', ref: 'tool-categories', not_active:<TcIcons stroke="#8496BB" fill='#fff' />, active:<TcIcons fill="#3D77E9" stroke="#3D77E9"/>},
        ]
    })
    const [tc, setTc] = useState({open: true, list: false})
    const [leftBar, setLeftbar] = useState(true)
    const [notif,  setNotif] = useState(false)
    const [myTool, setMyTool] = useState(false)

    return {
        tabs: {data: tabs, set: setTabs},
        tc: {data: tc, set: setTc},
        leftBar: {data: leftBar, set: setLeftbar},
        notif: {data: notif, set: setNotif},
        myTool: {data: myTool, set: setMyTool},
    }
}