// import { Box, Button, Dialog } from "@mui/material"
import {  Dialog } from "@mui/material"
import { Booking } from './Booking'
// import { Product } from "./Product";
// import { RealState } from "./RealState";
// import Cafgu from '../../../../assets/images/core/welcome-cafgu.svg';

export const OnBoarding = ({p13n_state}) => {
    const { openP, tour } = p13n_state
    const handlerNext = (value) => {
        tour.set(true)
        openP.set(false)
    }
    // const handlerProceed = () => {
    //     tour.set(true)
    //     openP.set(false)
    // }
    
    return (
        <Dialog open={openP.data} PaperProps={{ sx: { borderRadius: '16px' } }} >
            <Booking handlerNext={handlerNext} openP={openP} />
            {/* {
                step.data === 1 ?
                    <Booking handlerNext={handlerNext} openP={openP} />
                :
                    step.data === 2 ?
                        <Product handlerNext={handlerNext} />
                    :
                        <RealState handlerNext={handlerNext} />
            } */}
             {/* <Box maxWidth={{xs:'100%', sm:420}}>
                <img src={Cafgu} alt="cafgu-img" width="100%" height="100%" />
                <Box textAlign="center" p={2}>
                    <Box>Welcome to</Box>
                    <Box fontWeight="bold">Shop Personal</Box>
                    <Box component={Button} variant="contained" mt="12px" onClick={() => handlerProceed()}>
                        Proceed
                    </Box>
                </Box>
             </Box> */}
        </Dialog>
    )
}
