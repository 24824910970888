import { useState } from 'react'

export const RealState = () => {
    const [loader, setLoader] = useState(true)
    const [realproperty, setRealproperty] = useState({})
    const [realPropList, setRealPropList] = useState([])
    const [load, setLoad] = useState({loader: true, inftScroll: true})
    const [lidLimit, setLidLimit] = useState({val: 0, limit: 10})
    const [viewInfo, setViewInfo] = useState({details: {}, images: [], loader: true})
    const [showSlide, setShowSlide] = useState({val: false, index: 0})
    const [positionY, setPositionY] = useState(0)
    const [search, setSearch] = useState({val: '', active: false})

    return {
        loader: { data: loader, set: setLoader },
        realproperty: { data: realproperty, set: setRealproperty },
        realPropList: { data: realPropList, set: setRealPropList },
        load: { data: load, set: setLoad },
        lidLimit: { data: lidLimit, set: setLidLimit },
        viewInfo: { data: viewInfo, set: setViewInfo },
        showSlide: { data: showSlide, set: setShowSlide },
        positionY: { data: positionY, set: setPositionY },
        search: { data: search, set: setSearch },
    }
}