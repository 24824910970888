import { Box } from "@mui/material"
import { OnBoarding } from './onBoarding/Index'
import { Steps } from "./Steps"
import { useContext, useEffect } from "react"
import { ToolContext } from "../../../core/context/ToolContext"
import Joyride from "react-joyride"

export const Personalization = () => {
    const { p13n_state } = useContext(ToolContext)
    const { tour } = p13n_state

    const jrStyle = {
        options:{
            zIndex: 1202,
            width: 250,
            borderRadius:'15px',
        },
        buttonClose: {
            display: 'none'
        },
        buttonNext:{
            fontFamily: "Inter",
            fontWeight: 500,
            backgroundColor: "#0070EF",
            padding: "8px",
            fontSize: "12px !important",
            borderRadius: "15px",
            color: "#fff",
            cursor: "pointer",
            textAlign: "center",
            width: "80px",
            height:'33px'
        },
        buttonSkip:{
            fontFamily: "Inter",
            fontWeight: 500,
            padding: "8px",
            fontSize: "12px !important",
            color: "#A3A3A3",
            cursor: "pointer",
            textAlign: "center",
            width: "80px",
            height:'33px',
            left: 0,
            marginRight:'2px',
            float:'right'
        },
    }
    useEffect(() => {
        // eslint-disable-next-line
    }, [])
    
    return (
        <Box>
            <OnBoarding p13n_state={p13n_state} />
            <Joyride run={tour.data} styles={jrStyle} steps={Steps} continuous={true} locale={{skip: 'Close', last: 'Got it'}}
                disableScrolling hideBackButton={true} spotlightPadding={2}
            />
        </Box>
    )
}
