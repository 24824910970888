import { useState } from 'react'

export const PointStates = () => {
    const [open, setOpen] = useState({confirm: false, redeem: false})
    const [loader, setLoader] = useState(true)
    const [modalType, setModalType] = useState('')
    const [pswd, setPswd] = useState()
    const [redeem, setRedeem]= useState({code: '', name: '', id: '', company_id: ''})
    const [product, setProduct] = useState([])
    const [error, setError] = useState(false)
    const [redeemList, setRedeemList] = useState([])
    const [btnLoader, setBtnLoader] = useState(false)
    const [branch, setBranch] = useState([])
    
  return {
      open: {data: open, set: setOpen},
      loader: {data: loader, set: setLoader},  
      modalType: {data: modalType, set: setModalType} ,
      pswd: {data: pswd, set: setPswd},
      redeem:{data: redeem, set: setRedeem},
      product: {data: product, set: setProduct},
      error: {data: error, set: setError},
      redeemList: {data: redeemList, set: setRedeemList},
      btnLoader: {data: btnLoader, set: setBtnLoader},
      branch: {data: branch, set: setBranch},
  }
}
