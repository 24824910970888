import { useState } from "react"

export const Dashboard = () => {
    const [booking, setBooking] = useState({})
    const [loader, setLoader] = useState(true)
    return {
        booking: {data: booking, set: setBooking},
        loader: {data: loader, set: setLoader}
    }
}
