import { ToolStates } from './ToolStates';
import { NavigationStates } from './NavigationStates';
import { SwitchingState } from './SwitchingState';
import { ToolCategoryState } from './ToolCategoryState';
import { NotifState } from './NotifState';
import { BookingStates } from './BookingState';
import { PointStates } from './PointStates';
import { P13nState } from './P13nState';
import { Dashboard } from './Dashboard';
import { ProductState } from './ProductState'
import { CartState } from './CartState'
import { RealState } from './RealState';

export const Context = () => {
    return {
        tool_state: ToolStates(),
        navigation_state: NavigationStates(),
        switching_state: SwitchingState(),
        tool_category_state: ToolCategoryState(),
        notif_state: NotifState(),
        booking_state: BookingStates(),
        point_state: PointStates(),
        p13n_state: P13nState(),
        dashboard_state: Dashboard(),
        product_state: ProductState(),
        cart_state: CartState(),
        real_state: RealState()
    }
}