import { useState } from "react"
import logo from '../../../assets/images/icons/Icon6.png'
import logo1 from '../../../assets/images/core/avatar2.png'

export const CartState = () => {
    const [cartList, setCartList] = useState([])
    const [dummy, setDummy] = useState([
        {company_name: 'Dadas', list: [
            {id: 1, img: logo, product_name: 'Manok', product_details: 'test', discounted_price: 1450, original_price: 1700, quantity: 1, selected: false},
            {id: 2, img: logo1, product_name: 'Manok', product_details: 'test', discounted_price: 1450, original_price: 1700, quantity: 1, selected: false}
        ]},
        {company_name: 'Diwata', list: [
            {id: 3, img: logo, product_name: 'Siken', product_details: 'test', discounted_price: 1450, original_price: 1700, quantity: 1, selected: false}
        ]},
    ])
    const [successCart, setSuccessCart] = useState(false)
    const [loader, setLoader] = useState(false)
    const [cartCount, setCartCount] = useState(0)
    const [totalAmount, setTotalAmount] = useState(0)
    return {
        cartList: {data: cartList, set: setCartList},
        dummy: {data: dummy, set: setDummy},
        successCart: {data: successCart, set: setSuccessCart},
        loader: {data: loader, set: setLoader},
        cartCount: {data: cartCount, set: setCartCount},
        totalAmount: {data: totalAmount, set: setTotalAmount},
    }
}