import { useState } from 'react'

export const P13nState = () => {
    const [openP, setOpenP] = useState(false)
    const [tour, setTour] = useState(false)
    const [step, setStep] = useState(1)
    return {
        openP: {data:openP, set: setOpenP},
        tour: {data: tour, set: setTour},
        step: {data: step, set: setStep}
    }
}
