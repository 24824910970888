import { Box, Button, Fade } from '@mui/material'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

export const Booking = ({handlerNext, openP}) => {
    const nav = useHistory()

    const handlerBookNow = () => {
        openP.set(false)
        nav.push('/psl/booking/promo-list')
    }
    return (
        <Fade in={true}>
            <Box maxWidth={{xs:'100%', sm:420}} p={2}>
                <img src={"https://marketing-system-repo.s3.amazonaws.com/mt/phases/1707389297680.png"} alt="cafgu-img" width="100%" height="100%"  />
                <Box display="flex" flexDirection="column" gap="16px" >
                    <Box textAlign="center">
                        <Box fontWeight={600}>Sea of Clouds Overlooking Staycation House</Box>
                        <Box fontSize={12}>
                            "Offering a garden and lake view, Sea of clouds Overlooking Staycation house is set in Tanay, 49 km from Smart Araneta Coliseum"
                        </Box>
                    </Box>
                    {/* <Box display="flex" alignItems="center" justifyContent="center" gap="8px">
                        <Box width={8} height={8} bgcolor="#000" borderRadius="100px" />
                        <Box width={8} height={8} border="1px solid #000" borderRadius="100px" />
                        <Box width={8} height={8} border="1px solid #000" borderRadius="100px" />
                    </Box> */}
                    <Box display="flex" alignItems="center" gap="8px">
                        <Box component={Button} variant="contained" disableElevation fullWidth onClick={() => handlerBookNow()}>
                            Book Now
                        </Box>
                        <Box component={Button} variant="contained" disableElevation fullWidth onClick={() => handlerNext(2)}>
                            Next
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Fade>
    )
}
