import { useEffect } from "react"
import { Box } from "@mui/material"
import { Logo } from "../../../core/global/Icons"
import { SnakeLoader } from "../../../core/loader/SnakeLoader"
import { domain } from "../../../core/Env"

export const RegistrationAccount = () => {
    const urlParams = new URLSearchParams(window.location.search)
    const __SESSION = JSON.parse(localStorage.getItem('pofshop_session'))
    useEffect(() => {
        if (urlParams.get('acct') !== null) {
            // eslint-disable-next-line
            const callback = {
                cb: `${domain('tool').url}/sso/account/auth`,
                tid: null,
                tinfo: [{name:"POF Shop",logo:""}],
                expiration: "",
                platform: "personal",
                tsa_key: "PFSHP_SSO_RQX",
                pricing: null,
                subcb: "points",
                ref_a_frnd: null
            }
            if (__SESSION !== null) {
                if (__SESSION.ainfo.mobile_num === window.atob(urlParams.get('acct')).split('_')[1]) {
                    window.location.href = '/psl/myrewards/mypoints'
                    return
                }
            }
            window.location.href = `${domain('pm').url}/psl/registration/finalize?from=${encodeURIComponent(JSON.stringify(callback))}&sainfo={"va":"mobile_num","account":"${urlParams.get('acct')}"}&directReg=1&subcb=points`
        }
        // eslint-disable-next-line
    }, [])
    return (
        <Box height="100%" width="100%" display="flex" alignItems="center" justifyContent="center" flexDirection="column">
            <Logo size={60}/>
            <Box pt={4} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                <SnakeLoader  size="1rem" bg="#006FFF" distance="0.3rem" />
                <Box pt={2} fontWeight={500}>Validating your Account</Box> 
            </Box>
        </Box>
    )
}
