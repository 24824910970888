import { Suspense, useContext, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Box } from '@mui/material/';
import { ToolContext } from '../core/context/ToolContext';
import { WebRoute } from "../core/webrouter/pslRoute";
import { SnakeLoader } from '../core/loader/SnakeLoader'
import { Logo } from '../core/global/Icons';
import { InstallPromotion } from './components/layouts/installation/InstallPromotion';
import { rqx } from '../core/request/API';
import { env } from '../core/Env';
import { BrandLog } from '../core/global/BrandLog'
import { Personalization } from './components/personalization/Index'

const __SESSION = JSON.parse(localStorage.getItem('pofshop_session'))
const tool_id =  process.env.REACT_APP_TID_PSL;

export const Core = () => {
	const { tool_state, install_state, cart_state } = useContext(ToolContext)

	const __installer = () => {
		window.addEventListener('beforeinstallprompt', (e) => {
			e.preventDefault();
			install_state.prompt.set(e)
			installPromptHandler()
		});
	}

	const installPromptHandler = () => {
		setTimeout(() => {
			// install_state.ip.set({stat: true, prompt_type: 'first_prompt'})
		}, 2000);
	}
	
	useEffect(() => {
        BrandLog()
		const __init = async () => {
			// RUN AN API REQUEST HERE ( SAMPLE REQUESTS BELOW: )
            let ainfo;
            let pinfo;
            if (window.atob(__SESSION.ainfo.aid).split('_')[1] !== '0') {
                ainfo = await rqx.get(`<<global>>/${env()==='local'?'dev':env()}/account/read`, {aid: __SESSION.ainfo.aid})
                pinfo = await rqx.get(`<<global>>/${env()==='local'?'dev':env()}/primeinfo/read`, {aid: __SESSION.ainfo.aid})
            }else{
                ainfo = {account_type:2, aid:'YWNjb3VudF8w', email:'unknown@com', user_type:1}
                pinfo = [
                    {
                        aid:'YWNjb3VudF8w',
                        first_name:'Guest',
                        last_name:'Only',
                        middle_name:"",
                        suffix:"",
                        contact_info:"{}",
                        other_info:"{\"gender\": null, \"birthdate\": null, \"civil_status\": null}",
                        profile:"{\"cover\": \"https://pofsis-business.s3.amazonaws.com/biz-image/DefaultBG.png\", \"profile\": \"https://pofsis-business.s3.amazonaws.com/biz/dev/1711527623238.jpg\"}",
                        role:0
                    }
                ]
            }
			let uaccess = await rqx.get(`<<global>>/${env()==='local'?'dev':env()}/roleaccess/uap`, {aid: __SESSION.ainfo.aid, tid:tool_id})
			let uaddress = await rqx.get(`<<global>>/${env()==='local'?'dev':env()}/location/assigned`, {aid:__SESSION.ainfo.aid})
            if (__SESSION !== null) {
                let c_count = await rqx.get(`<<tool>>/${env()}/cart/read`, {user_id: parseInt(window.atob(ainfo.aid).split('_')[1]), action: 'count'})
                cart_state.cartCount.set(c_count)
            }
			await tool_state.__SESSION.set({ainfo, pinfo, uaccess, uaddress})
            
			// SET STATES HERE ( SAMPLE STORING BELOW: )
			// let d = new Date()
			// d.setDate(d.getDate() + 30);
			// localStorage.setItem('pofshop_session', JSON.stringify({expiration: `${d.getMonth()+1}${d.getUTCDate()}${d.getUTCFullYear()}`, ainfo: ainfo}))
			tool_state.userId.set(parseInt(window.atob(ainfo.aid).split('_')[1]))
			tool_state.prefetch.set(true)
			// RUN PWA INSTALLATION PROMPT HANDLER
			// installPromptHandler()
		}
        if (window.location.pathname === '/') {
            window.location.href = '/psl'
        }
		if (__SESSION !== null) {
			__installer()
			__init()
		} else {
			setTimeout(() => {
				// SET STATES HERE ( SAMPLE STORING BELOW: )
				tool_state.prefetch.set(true)

				// RUN PWA INSTALLATION PROMPT HANDLER
				
				installPromptHandler()
			}, 1000);
		}
		
		console.log(`Shop Personal Version V1.4.5 is running`);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
    
	return (
		<Box width="100%" height="100%" >
			{
				tool_state.prefetch.data ? (
					<Router>
						<Suspense fallback={
							<Box height="100%" width="100%" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
								<Logo size={60}/>
								<Box mt={4}>
									<SnakeLoader size="1rem" bg="#3D77E9" distance="0.3rem" />
								</Box>
							</Box>
						}>
							<WebRoute />
                            <Personalization />
						</Suspense>
					</Router>
				) : (
					<Box height="100%" width="100%" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
						<Logo size={60}/>
						<Box mt={4}>
							<SnakeLoader size="1rem" bg="#3D77E9" distance="0.3rem" />
						</Box>
					</Box>
				)
			}
			<InstallPromotion install_state={install_state} tool_state={tool_state} />
		</Box>
	)
}